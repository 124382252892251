import React from "react";
import styles from "./PageNotFound.module.css";
import oopsie from "../../assets/logos/404.svg";

function PageNotFound() {
  return (
    <div className={styles.display}>
      <img alt="Page Not Found" src={oopsie} />
    </div>
  );
}

export default PageNotFound;
