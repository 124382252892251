import React from 'react';
import styles from './ScrollArrow.module.css';

const ScrollArrow = () => {
	return (
		<div className={`${styles.arrow}`}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	)
}

export default ScrollArrow