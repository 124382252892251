import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import {events} from "../../data/contents";

function Memory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
      <div>
      <Heading text="Riddle Race" subtext="VYUHAA'23" />
      <ContentBox
        desc={events.riddlerace.desc}
        venue={events.riddlerace.venue}
        time={events.riddlerace.time}
        contacts={events.riddlerace.contact}
        rounds={events.riddlerace.round}
        teamSize={events.riddlerace.teamSize}
        img_src={events.riddlerace.img_src}
        
      />
    </div>
    );
}

export default Memory;