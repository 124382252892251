import { React, useState, useRef, useEffect } from "react";
import styles from "./WorkshopRegister.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { validateWorkshopReg } from "../../validators/workshopReg";
import { apiWorkshopRegister } from "../../api/workshopRegister";
import DialogPrompt from "../DialogPrompt/DialogPrompt";
import Loader from "../Loader";
function WorkshopRegister() {
  const [vid, setVid] = useState("");
  const [ws, setWs] = useState("A");

  const [loading, setloading] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    message: "",
  });

  let reCaptchaRef = useRef(null);

  const clickSubmit = async () => {
    // Form Validation
    
    let validation = validateWorkshopReg(
      vid,
    );

    if (validation.status === false) {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: validation.message,
      });
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: "Please select reCaptcha",
      });
      return;
    }
    
    setloading(true);
    
    const resp = await apiWorkshopRegister({
      vid: vid,
      workshops: ws.split(" "),
      captcha: reCaptchaRef.current.getValue(),
    });
    
    reCaptchaRef.current.reset();

    setloading(false);

    if (resp === undefined) {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: "Please try again after sometime!",
      });
    } else {
      if (resp.status === 200) {
        setDialogConfig({
          isOpen: true,
          result: true,
          message: resp.data.message,
        });
        setVid("");
        setWs("");
      } else if (resp.status >= 400 && resp.status < 500) {
        setDialogConfig({
          isOpen: true,
          result: false,
          message: resp.data.message,
        });
      } else if (resp.status >= 500 && resp.status < 600) {
        setDialogConfig({
          isOpen: true,
          result: false,
          message: "Server Error. Try again after sometime!",
        });
      }
    }
  };

  return (
    <div className={styles.display}>
      <p className={`${styles.header}`}>Registration</p>
      <p className={`${styles.msgHeader}`}>Book your slot</p>
      {loading && (
        <div className={styles.loader_container}>
          <Loader />
        </div>
      )}
      <div
        style={{ display: loading && "none" }}
        className={`${styles.form_field_wrapper}`}
      >
        <div className={`${styles.form_field_righthalf}`}>
          <div className={styles.form_field}>
            <input
              type="text"
              className="form-control"
              placeholder="Vyuhaa ID"
              value={vid}
              onChange={(e) => setVid(e.target.value)}
            />
          </div>

          {/* <div className={`${styles.form_field} ${styles.vertical_flex}`}>
            <div className={styles.choice_caption}>Which workshops are you registering for?</div>
            <select
              className="form-select"
              name="workshops"
              value={ws}
              onChange={(e) => setWs(e.target.value)}
            >
              <option value="" disabled selected>
                Select Workshops
              </option>
              <option value="EV">Electric Vehicles Fundamentals</option>
              <option value="ML">Machine Learning</option>
              <option value="EV ML">Both</option>
            </select>
          </div> */}
          <div className={`${styles.recaptcha_container}`}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              theme="dark"
              size="normal"
              className={`${styles.recaptcha}`}
              ref={reCaptchaRef}
            />
          </div>
          <br />
          <button
            type="button"
            className={`${styles.bt}`}
            // onClick={clickSubmit}
            disabled
          >
            Registration closed
          </button>
        </div>
        <DialogPrompt
          dialogConfig={dialogConfig}
          setDialogConfig={setDialogConfig}
        />
      </div>
    </div>
  );
}

export default WorkshopRegister;

