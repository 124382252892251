import { MutatingDots } from 'react-loader-spinner'

function Loader() {
  return (
     <MutatingDots
        height="100"
        width="100"
        color="#00475e"
        secondaryColor="#562c2c"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
     />
  );
}

export default Loader;
