import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function Crunch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Circuit Mania" subtext="VYUHAA'23" />
      <ContentBox
        desc={events.circuitmania.desc}
        venue={events.circuitmania.venue}
        time={events.circuitmania.time}
        contacts={events.circuitmania.contact}
        rounds={events.circuitmania.round}
        teamSize={events.circuitmania.teamSize}
        img_src={events.circuitmania.img_src}
      />
    </div>
  );
}

export default Crunch;