import React from "react";
import styles from "./Heading.module.css";

function Heading(props) {
    return (
        <div className={styles.display}>
            <div className={styles.text}>
                {props.text}
            </div>
            <div className={styles.subtext}>
                {props.subtext}
            </div>
        </div>
    );
}

export default Heading;