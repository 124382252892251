import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function TileTitans() {
      useEffect(() => {
            window.scrollTo(0, 0);
      }, []);
      return (
            <div>
                  <Heading text="Tile Titans" subtext="VYUHAA'23" />
                  <ContentBox
                        desc={events.tiletitans.desc}
                        venue={events.tiletitans.venue}
                        time={events.tiletitans.time}
                        contacts={events.tiletitans.contact}
                        rounds={events.tiletitans.round}
                        teamSize={events.tiletitans.teamSize}
                        img_src={events.tiletitans.img_src}
                  />
            </div>
      );
}

export default TileTitans;