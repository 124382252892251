import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function Quiz() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Tech Fusion" subtext="VYUHAA'23" />
      <ContentBox
        desc={events.techfusion.desc}
        venue={events.techfusion.venue}
        time={events.techfusion.time}
        contacts={events.techfusion.contact}
        rounds={events.techfusion.round}
        teamSize={events.techfusion.teamSize}
        img_src={events.techfusion.img_src}
      />
    </div>
  );
}

export default Quiz;