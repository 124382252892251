import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import logo from "../../assets/logos/Vlogo.png";
import {
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa";

function Footer() {

  /* To navigate back */
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  /* To avoid navigate back in home page - stores current location*/
  const location = useLocation();

  const [active, setActive] = useState(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  /* Link opener */
  const openURL = (url) => {
    window.open(url);
  };

  const iconSize = 25;

  return (
    <div className={styles.display}>
      {active != "/" ? (
        <div className={styles.back_nav}>
          <img src={logo} alt="back" onClick={() => goBack()} />
        </div>
      ) : null}
      <div className={styles.icons}>
        <div
          className={styles.iconBox}
          onClick={() => openURL("https://www.instagram.com/ceg_tech_forum/")}
        >
          <FaInstagram size={iconSize} />
        </div>
        <div
          className={styles.iconBox}
          onClick={() => openURL("https://www.facebook.com/techforum.ceg/")}
        >
          <FaFacebook size={iconSize} />
        </div>
        <div
          className={styles.iconBox}
          onClick={() =>
            openURL("https://www.linkedin.com/company/ceg-tech-forum/")
          }
        >
          <FaLinkedin size={iconSize} />
        </div>
        {/* <div className={styles.iconBox} onClick={() => openURL("https://twitter.com/ceg_tech_forum")}>
          <FaTwitter size={iconSize} />
        </div> */}
        <div
          className={styles.iconBox}
          onClick={() =>
            openURL("https://www.youtube.com/channel/UC3eAUaFdDAOlI8I2SYCANbg")
          }
        >
          <FaYoutube size={iconSize} />
        </div>
        <div
          className={styles.iconBox}
          onClick={() => openURL("Mailto:hr@cegtechforum.in")}
        >
          <FaEnvelope size={iconSize} />
        </div>
      </div>

      <div className={styles.credits}>
        <p>
          Designed & Developed by
          <span
            onClick={() =>
              openURL("https://gitlab.com/groups/ctf-tech-2024/-/group_members")
            }
          >
            {"  "}
            CEG&nbsp;Tech&nbsp;Forum
          </span>
        </p>
      </div>

      <div className={styles.copyrights}>ⓒ 2023 Copyright CTF</div>
    </div>
  );
}

export default Footer;
