import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function BNB() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Codezilla" subtext="VYUHAA'23" />
      <ContentBox
        desc={events.codezilla.desc}
        venue={events.codezilla.venue}
        time={events.codezilla.time}
        contacts={events.codezilla.contact}
        rounds={events.codezilla.round}
        teamSize={events.codezilla.teamSize}
        img_src={events.codezilla.img_src}
      />
    </div>
  );
}

export default BNB;