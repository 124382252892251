import React, { useEffect } from "react";
import styles from "./Workshops.module.css";
import Heading from "../../components/Heading/Heading";
import { workshopsList } from "../../data/WorkshopsList";
import { useNavigate } from "react-router-dom";
import WorkshopRegister from "../../components/WorkshopRegister/WorkshopRegister";

function Workshops() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <div className={styles.display}>
      <Heading text="Workshop" subtext="VYUHAA'23" />
      <div className={styles.body}>
        <div className={styles.contentContainer}>
          {workshopsList.map((workshop, index) => {
            return (
              <div className={styles.content} key={index}
                onClick={() => navigate(workshop.cname)}
              >
                {workshop.name}
              </div>
            );
          })}
        </div>
        <WorkshopRegister/>
      </div>
    </div>
  );
}

export default Workshops;
