export const events = {
	"codezilla": {
		desc: [
			"Get set for a coding extravaganza like never before! Join us on an exhilarating journey where your coding prowess will be put to the ultimate test in pair-coding challenges.", "Script your success, collaborate with your coding partner, and unleash the adventure! Are you ready to discover the next level of coding mastery? Get ready to conquer!"
		],
		venue: "Red Building",
		time: "10.00AM - 12.30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/websiteCodezilla.jpg`,
		round: 2,
		teamSize: "1 - 2",
	},
	"circuitmania": {
		desc: [
			"Gear up for an electrifying electronic quiz where knowledge and technology collide in an amazing circuital maze!", "Unleash your inner Circuit Master and navigate through innovation and competition.It's not just a quiz; it's a journey into the heart of circuits and creativity. ", "Plug in and experience the ultimate fusion of fun and intellect!"
		],
		venue: "Red Building",
		time: "10.00AM - 12.30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],

		img_src: `${process.env.PUBLIC_URL}/images/Circuitmania.jpg`,
		round: 2,
		teamSize: "1 - 3",
	},
	"techfusion": {
		desc: [
			"Join us for a spectacular event that goes beyond boundaries. Immerse yourself in the transformation of techniques and ideas, crafting innovative solutions for society.", "This is not just an event; it's a platform to test your smart mind in the most fabulous way imaginable.", " Let's fuse ideas, spark creativity, and build a tech- driven future together! Are you ready to unleash the power of innovation?"
		],
		venue: "Red Building",
		time: "10.00AM - 12.30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/websiteTechFusion.jpg`,
		round: 2,
		teamSize: "4",
	},
	"kryptics": {
		desc: [
			"Dive into a realm of engineering principles, technical words, and universal facts. Get ready to challenge your analytical and thinking skills as you decipher clues from pictures.",
			"Do you excel at framing things and possess a powerful imagination to connect the dots? This event is your canvas to display your unique skills and engineering expertise.",
			"Join the quest for connection and elevate your thinking to the next level!",
		],
		venue: "Red Building",
		time: "10.30AM - 12.30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/WebsiteKryptics.jpg`,
		round: 2,
		teamSize: "1 - 3",
	},
	"riddlerace": {
		desc: [
			"Get ready for a mind-blowing adventure that fuels your brainpower and enhances your problem-solving skills! ", "Every challenge here is a thrilling journey that develops memory, planning, and idea-testing abilities.", "Keep your brain engaged, chase the unseen, and unleash the adventure of discovering the next level of exhilarating solutions.Are you ready to think, solve, and succeed ? Join the race to sharpen your mind."
		],
		venue: "Red Building",
		time: "10.00AM -12.30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/RiddleRace.jpg`,
		round: 2,
		teamSize: "1- 3",
	},
	"memory": {
		desc: [
			"Embark on a memory adventure where every list is a challenge waiting to be conquered! Sharpen your mind, one item at a time, as you master the art of remembering.",
			"List it, remember it, and experience the thrill of cognitive triumph! Join us in the pursuit of memory mastery, where your recall skills take center stage.",
			"Are you ready to create your own memory archive?",
		],
		venue: "Red Building",
		time: "10:30AM - 12:30PM",
		contact: [
			{
				name: "Abinaya",
				phone: "+91 93605 12675",
			},
			{
				name: "Gokulnath",
				phone: "+91 75984 14166",
			},
			{
				name: "Sridhar",
				phone: "+91 90807 40567"
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/websiteMemoryArchive.jpg`,
		round: 2,
		teamSize: "1 - 2",
	},
	"tiletitans": {
		desc: [
			"Unleash your inner wordsmith in 'Tile Titans'—where the timeless elegance of Scrabble collides with the cutting-edge world of tech! Embark on a thrilling wordplay journey where strategy meets vocabulary in a groundbreaking event.",
			"Join us to test your problem-solving prowess with tech-themed challenges, and revel in the joy of words like never before. Let the tiles fall and the tech rise in this epic fusion of language and innovation!"
		],
		venue: "Red Building",
		time: "10:00AM - 04:00PM",
		contact: [
			{
				name: "Akshaya",
				phone: "+91 96774 84102"
			},
			{
				name: "Praveen Kumar",
				phone: "+91 83001 15259"
			}

		],
		img_src: `${process.env.PUBLIC_URL}/images/TitleTitans.jpg`,
	}
}

export const workshops = {
	"A": {
		desc: [
			"Join us for a half-day free workshop that's perfect for beginners eager to dive into the world of Arduino and Tinkercad simulation. Discover the potential of Arduino and explore its wide-ranging applications. Learn how to effectively simulate Arduino hardware using Tinkercad and get your hands busy with practical sessions, guided by an Arduino board.","Don't miss out on this opportunity to kickstart your Arduino journey in a fun and educational way. Reserve your spot now and embark on an exciting learning adventure with us!"
		],
		venue: "X hall",
		moreVenue: "EEE dept",
		time: "1:30PM - 4:30PM",
		speaker: {
			name: "Mr. Hariharan D",
			designation: "Team lead - QMax systems Inc",
		},
		contact: [
			{
				name: "Jaswant S",
				phone: " +91 73588 24698",
			},
			{
				name: "Sujith Vishal V",
				phone: "+91 80569 08993",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/websiteArduino.jpg`,

	},

}

// export const ktalk = {
// 	desc: [
// 		"K!Talks is a showcase of speakers presenting on interesting tech and non-tech ideas and motivational speeches that will enlighten students with new ideas and inspire the gathered community.",
// 		"Topic: Technical challenges and experiences in making of KALAM-SAT and how students can groom themselves to build their own satellites.",
// 		"About the speaker: Srimathy Kesan is the founder and CEO of Space Kidz India, and is also a WEF Educational, cultural and Travel ambassador for kids."
// 	],
// 	venue: "Vivek Audi",
// 	time: "5:00PM",
// 	speaker: {
// 		name: "Dr. Srimathy Kesan",
// 		designation: "Founder, CEO - Space Kidz India",
// 	},
// 	contact: [
// 		{
// 			name: "Hemananthini",
// 			phone: "+91 90259 67826",
// 		}
// 	],
// 	img_src: `${process.env.PUBLIC_URL}/images/ktalk.jpg`,
// 	yt_src: "https://www.youtube.com/embed/T1uPL2iKVIE?list=PLG6EkHidBHF49t6J-Orr_POPxds6iSnUR",
// }

export const innodesign = {
	desc: [
		"Unleash your creativity and technical prowess in the ultimate design showdown – INNO DESIGN! Where innovation meets imagination, and problems meet solutions.", "Get ready for a thrilling challenge as we present real-world problem statements begging for your ingenious designs. Transform your ideas into reality! From wireframes to prototypes, witness the evolution of your solutions and redefine the boundaries of design and technology.","Compete with the brightest minds, showcase your design finesse, and vie for the coveted title of INNO DESIGN Champion!"
	],
	venue: "Red Building",
	time: "11:00AM - 4:00PM",
	teamSize: "1 - 4",
	contact: [
		{
			name: "Balamurugan",
			phone: "+91 73395 84875",
		},
		{
			name: "Nisha",
			phone: "+91 87781 74956",
		},
		{
			name: "Sauman Raaj",
			phone: "+91 94879 47233",
		},

	],
	img_src: `${process.env.PUBLIC_URL}/images/innodesign.jpg`,

}