import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { innodesign } from "../../data/contents";

function Innodesign() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="InnoDesign" subtext="VYUHAA'23" />
      <ContentBox
        desc={innodesign.desc}
        venue={innodesign.venue}
        time={innodesign.time}
        contacts={innodesign.contact}
        img_src={innodesign.img_src}
      />
    </div>
  );
}

export default Innodesign;