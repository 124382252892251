import React, { useState } from "react";
import styles from "./ContentBox.module.css";
import Detail from "../../components/Detail/Detail";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import Loader from "../Loader";
function ContentBox(props) {
  const [imgLoad, setImgLoad] = useState(true);

  return (
    <div className={styles.display}>
      <div className={styles.textBox}>
        <ul className={styles.list}>
          {props.desc.map((point, index) => {
            return (
              <li className={styles.lis} key={index}>
                {point}
              </li>
            );
          })}
        </ul>

        <div className={styles.detail_container}>
          <Detail text={props.venue} subtext={props.moreVenue ?? "Venue"} />
          <Detail text={props.time} subtext={"Time"} />
        </div>
        {props.speaker ? (
          <>
            <HorizontalDivider />
            <div className={styles.detail_container}>
              <Detail
                text={props.speaker.name}
                subtext={props.speaker.designation}
              />
            </div>
          </>
        ) : null}

        {props.rounds ? (
          <>
            <HorizontalDivider />
            <div className={styles.detail_container}>
              <Detail text={props.rounds} subtext={"Rounds"} />
              <Detail text={props.teamSize} subtext={"Team Size"} />
            </div>
          </>
        ) : null}

        <HorizontalDivider />

        <div className={styles.detail_container}>
          {props.contacts.map((contact, index) => {
            return (
              <Detail key={index} text={contact.name} subtext={contact.phone} />
            );
          })}
        </div>
      </div>
      <div className={styles.imgBox}>
        {props.yt_src ? (
          <iframe
            title="YouTube video player"
            src={props.yt_src}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="300"
            height="300"
            allowfullscreen
          ></iframe>
        ) : (
          <>
            {imgLoad &&
             <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
             }}>
             <Loader />
             </div>
             }
            <img
              src={props.img_src}
              onLoad={() => setImgLoad(false)}
              style={{ display: imgLoad ? "none" : "block" }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ContentBox;
