export const deptsList = [
  "BIO MEDICAL ENGINEERING",
  "CIVIL ENGINEERING",
  "COMPUTER SCIENCE AND ENGINEERING",
  "ELECTRICAL AND ELECTRONICS ENGINEERING",
  "ELECTRONICS AND COMMUNICATION ENGINEERING",
  "GEO INFORMATICS",
  "INDUSTRIAL ENGINEERING",
  "INFORMATION SCIENCE AND TECHNOLOGY",
  "MANUFACTURING ENGINEERING",
  "MATERIAL SCIENCE AND ENGINEERING",
  "MECHANICAL ENGINEERING",
  "MINING ENGINEERING",
  "PRINTING AND PACKING TECHNOLOGY",
  "OTHERS"
];

export const campusList = [
  "CEG",
  "MIT",
  "ACT",
  "SAP"
]

export const yearList = [
  1
];