export const eventsList = [
    {
        id: 1,
        name: 'CODEZILLA',
        cname: 'codezilla',
        fresher: 'no',
    },
    {
        id: 2,
        name: 'CIRCUIT MANIA',
        cname: 'circuitmania',
        fresher: 'no',
    },
    {
        id: 3,
        name: 'TECH FUSION',
        cname: 'techfusion',
        fresher: 'no',
    },
    {
        id: 4,
        name: 'RIDDLE RACE',
        cname: 'riddlerace',
        fresher: 'no',
    },
    {
        id: 5,
        name: 'KRYPTICS',
        cname: 'kryptics',
        fresher: 'no',
    },
    {
        id: 6,
        name: 'MEMORY ARCHIVE',
        cname: 'memoryarchive',
        fresher: 'no',
    },
    {
        id: 7,
        name: 'TILE TITANS',
        cname: 'tiletitans',
        fresher: 'yoh',
    }
];