import React from "react";
import Dialog from "@mui/material/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./DialogPrompt.module.css";

function DialogPrompt({ dialogConfig, setDialogConfig }) {
  const clickedClose = () => {
    setDialogConfig({
      ...dialogConfig,
      isOpen: false,
    });
  };

  return (
    <Dialog open={dialogConfig.isOpen} className={`${styles.dialog}`}>
      <div className={`${styles.dialog_wrapper}`}>
        <CloseIcon className={`${styles.close_icon}`} onClick={clickedClose} />
        <p>
          {dialogConfig.result ? (
            <CheckCircleIcon
              style={{ color: "green" }}
              className={`${styles.icon}`}
            />
          ) : (
            <CancelIcon style={{ color: "red" }} className={`${styles.icon}`} />
          )}
          &nbsp; {dialogConfig.message}
        </p>
      </div>
    </Dialog>
  );
}

export default DialogPrompt;
