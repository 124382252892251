import React from "react";
import styles from "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Events from "./pages/Events/Events";
import Workshops from "./pages/Workshops/Workshops";
// import Ktalk from "./pages/Ktalk/Ktalk";
import Projects from "./pages/Projects/Projects";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Footer from "./components/Footer/Footer";
import Codezilla from "./pages/Events/Codezilla";
import Crunch from "./pages/Events/CircuitMania";
import Memory from "./pages/Events/Memory";
import Kryptics from "./pages/Events/Kryptics";
import RiddleRace from "./pages/Events/RiddleRace";
import TechFusion from "./pages/Events/TechFusion";
import TileTitans from "./pages/Events/TileTitans";
import Arduino from "./pages/Workshops/Arduino";

import Particle from "./components/Particle";

function App() {
  return (
    <div className="App">
      <Particle />
      <div className="SB">
        <Router>
          <div className="bg">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/events" exact element={<Events />} />
              <Route path="/events/codezilla" exact element={<Codezilla />} />
              <Route path="/events/circuitmania" exact element={<Crunch />} />
              <Route path="/events/techfusion" exact element={<TechFusion />} />
              <Route path="/events/riddlerace" exact element={<RiddleRace/>} />
              <Route path="/events/kryptics" exact element={<Kryptics />} />
              <Route path="/events/memoryarchive" exact element={<Memory />} />
              <Route path="/events/tiletitans" exact element={<TileTitans />} />
              <Route path="/workshops" exact element={<Workshops />} />
              <Route path="/workshops/arduino" exact element={<Arduino />} />

              {/* <Route path="/ktalk" exact element={<Ktalk />} /> */}
              {/* <Route path="/vr" exact element={<Karnival />} /> */}
              <Route path="/innodesign" exact element={<Projects />} />

              <Route path={"*"} exact element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
