import { React, useState, useRef } from "react";
import styles from "./Registration.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { validateRegForm } from "../../validators/regForm";
import { apiRegisterUser } from "../../api/registerUser";
import DialogPrompt from "../../components/DialogPrompt/DialogPrompt";
import Loader from "../../components/Loader";
import { yearList, campusList, deptsList } from "../../data/DeptList";

function Registration() {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [campus, setCampus] = useState("");
  const [year, setYear] = useState("");
  const [rno, setRno] = useState("");
  const [dept, setDept] = useState("");

  const [loading, setloading] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    message: "",
  });

  let reCaptchaRef = useRef(null);

  const clickSubmit = async () => {
    // Form Validation
    let validation = validateRegForm(
      name,
      dept,
      year,
      rno,
      campus,
      email,
      contact,
    );

    if (validation.status === false) {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: validation.message,
      });
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: "Please select reCaptcha",
      });
      return;
    }
    
    setloading(true);

    const resp = await apiRegisterUser({
      name: name,
      phone: contact,
      email: email,
      campus: campus,
      dept: dept,
      year: year,
      rollno: rno,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();

    setloading(false);
    
    console.log(resp);

    if (resp === undefined) {
      setDialogConfig({
        isOpen: true,
        result: false,
        message: "Please try again after sometime!",
      });
    } else {
      if (resp.status === 201) {
        setDialogConfig({
          isOpen: true,
          result: true,
          message: resp.data.message,
        });
        setName("");
        setDept("");
        setYear("");
        setRno("");
        setCampus("");
        setEmail("");
        setContact("");
      } else if (resp.status >= 400 && resp.status < 500) {
        setDialogConfig({
          isOpen: true,
          result: false,
          message: resp.data.message,
        });
      } else if (resp.status >= 500 && resp.status < 600) {
        setDialogConfig({
          isOpen: true,
          result: false,
          message: "Server Error. Try again after sometime!",
        });
      }
    }
  };

  return (
    <div className={styles.display}>
      <p className={`${styles.header}`}>Registration</p>
      <p className={`${styles.msgHeader}`}>get your Vyuhaa ID</p>
      {loading && (
        <div className={styles.loader_container}>
          <Loader />
        </div>
      )}
      <div
        style={{ display: loading && "none" }}
        className={`${styles.form_field_wrapper}`}
      >
        <div className={`${styles.form_field_lefthalf}`}>
          <div className={styles.form_field}>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.form_field}>
            <input
              type="text"
              className="form-control"
              placeholder="Contact number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className={styles.form_field}>
            <input
              type="text"
              className="form-control"
              placeholder="E-mail ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.form_field}>
            <select
              className="form-select"
              name="campus"
              value={campus}
              onChange={(e) => setCampus(e.target.value)}
            >
              <option value="" disabled selected>
                Campus
              </option>
              {campusList.map((campus) => {
                return <option value={campus}>{campus}</option>;
              })}
              ;
            </select>
          </div>
          <div className={styles.form_field}>
            <select
              className="form-select"
              name="dept"
              value={dept}
              onChange={(e) => setDept(e.target.value)}
            >
              <option value="" disabled selected>
                dept
              </option>
              {deptsList.map((dept) => {
                return <option value={dept}>{dept}</option>;
              })}
              ;
            </select>
          </div>
        </div>
        <div className={`${styles.form_field_righthalf}`}>
          <div className={styles.form_field}>
            <select
              className="form-select"
              name="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="" disabled selected>
                Year
              </option>
              {yearList.map((yearOpn) => {
                return <option value={yearOpn}>{yearOpn}</option>;
              })}
              ;
            </select>
          </div>
          <div className={styles.form_field}>
            <input
              type="text"
              className="form-control"
              placeholder="Registration number"
              value={rno}
              onChange={(e) => setRno(e.target.value)}
            />
          </div>
          <br />
          <div className={`${styles.recaptcha_container}`}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              theme="dark"
              size="normal"
              className={`${styles.recaptcha}`}
              ref={reCaptchaRef}
            />
          </div>
          <br />
          <br />
          <button
            type="button"
            className={`${styles.bt}`}
            // onClick={clickSubmit}
            disabled
          >
            Registration closed
          </button>
        </div>
        <DialogPrompt
          dialogConfig={dialogConfig}
          setDialogConfig={setDialogConfig}
        />
      </div>
    </div>
  );
}

export default Registration;
